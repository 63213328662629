


















import Component, { mixins } from 'vue-class-component'
import api from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import { downloadBlob } from '~lib/download'
import { Prop } from 'vue-property-decorator'
import timeout from '~lib/timeout'


@Component
export default class DepositDownload extends mixins(ActionFormMixin) {
  @Prop({ type: String, required: true })
  public repositoryId!: string
  data() {
    return {
      progress: 0,
      value: 0,
      query: false,
      show: true
    }
  }
  loading = false;
  downloadForbidden = true;


  @Prop({ type: String, required: true })
  public depositId!: string
  public latestEvent: ProgressEvent<XMLHttpRequestEventTarget> | null = null
  public downloadForbidden = true

  get status() {
    if (!this.latestEvent || !this.latestEvent.lengthComputable || this.latestEvent.loaded === 0) {
      return 'preparing'
    } else {
      return 'downloading'
    }
  }


  mounted() {
    this.isDownloadForbidden()
  }

  async isDownloadForbidden() {
    // vérifier si on a le droit de downloader le dépôt (dans le but de désactiver/réactiver le bouton au besoin)
    const rep = await api.deposits.checkdownload(this.repositoryId, this.depositId)
    if (rep.status === 200) {
      this.downloadForbidden = (rep.data.auth !== 'OK')
      return
    }
    throw new Error(res.status)
  }

  async action() {
    this.latestEvent = null;
    this.loading = true;
    const totalSteps = 10;
    for (let i = 0; i < totalSteps; i++) {
      await new Promise((resolve) => setTimeout(resolve, 500));

      this.progress = Math.ceil(((i + 1) / totalSteps) * 100);
    }

    this.loading = false;
    const depositBlob = (await api.deposits.download(this.repositoryId, this.depositId, (e) => {
      this.latestEvent = e
    })).data

    downloadBlob(depositBlob, 'deposit-' + this.$route.params.id + '.zip');

  }

  public getErrorMessage(e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'deposits list' })
    }
    return undefined
  }
}
