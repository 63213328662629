

















































































































































import Component, { mixins } from 'vue-class-component'
import { IDeposit, IOrganization } from '~api'

import ActionFormMixin from '~lib/action_form'
import { Prop } from 'vue-property-decorator'
import DepositDownload from '~pages/deposits/download.vue'

@Component({ components: { DepositDownload } })
export default class DepositDialog extends mixins(ActionFormMixin) {
  @Prop({ type: Boolean, default: false })
  public viewDialog!: boolean

  @Prop({ type: Object, default: null })
  public deposit!: IDeposit

  @Prop({ type: Object, default: null })
  public organization!: IOrganization

  @Prop({ type: Object, default: null })
  public repository!: string

  mounted () {
    this.submit()
  }

  public closeDialog () {
    this.$emit('viewDialog', false)
    this.$emit('selectedDeposit', null)
  }
}
