



































import Component, { mixins } from 'vue-class-component'
import api, { IUserLockedInfo } from '~api'
import ActionFormMixin, {IActionError} from '~lib/action_form'
import { DataTableHeader } from 'vuetify/types'
import timeout from '~lib/timeout'

@Component
export default class Requests extends mixins(ActionFormMixin) {
  public lockedAccounts: IUserLockedInfo[] | null = []

  mounted () {
    this.submit()
  }

  async action () {
    this.lockedAccounts = (await api.locked.list()).data
  }

  get headers (): DataTableHeader[] {
    return [
      {
        text: this.$t('locked.list.name') as string,
        value: 'name'
      },
      {
        text: this.$t('locked.list.mail') as string,
        value: 'mail'
      },
      {
        text: this.$t('locked.list.role') as string,
        value: 'createdAt'
      },
      {
        text: this.$t('locked.list.organization') as string,
        value: 'role'
      },
      {
        text: this.$t('locked.list.date') as string,
        value: 'date'
      }
    ]
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
