import { AxiosError, AxiosResponse } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { LocaleMessage } from 'vue-i18n'
import { captureException } from '~lib/sentry'
import { VInputRules } from '~lib/vinput_rules'

export interface IActionError {
  error: AxiosError | unknown
  response: AxiosResponse | null
  status: number | null
  data: unknown
}

export type IForm = Element & { validate (): boolean, resetValidation (): void }

export type IActionErrorMessage = string | { key: string, params: Record<string, string> } | undefined | void
export type IActionFormRefs = Record<string, Vue | Element | Vue[] | Element[]> & {
  // Vuetify does not export actual types
  form: IForm | IForm[] | undefined
}

// tslint:disable-next-line:no-any
export interface IActionForm<ActionParams extends (any[] | []) = []> {
  loading: boolean
  errorMessage?: string
  errorParams?: Record<string, string>
  $refs: IActionFormRefs

  action (...params: ActionParams): Promise<void>
  getErrorMessage (_e: IActionError): IActionErrorMessage
  onError? (_e: IActionError): void
}

@Component
export default class ActionFormMixin<ActionParams extends (unknown[] | []) = []> extends Vue {
  public loading = false
  public errorMessage: LocaleMessage = ''
  public errorParams: Record<string, string> = {}
  public valid = true
  public patternMail: RegExp = /^([A-Za-z0-9\-.]){1,50}@([A-Za-z0-9\-.]){1,30}\.([A-Za-z0-9\-.]){1,10}$/
  public patternName: RegExp = /^(?:[^\d\W][\-\s'éèëêáàäâíìîïóòöôøÈÉÊËÌÍÎÏÑåØÖÔÓÒÜÛÙÚùúûüñÇç]?){2,20}$/

  public $refs!: Record<string, Vue | Element | Vue[] | Element[]> & {
    // Vuetify does not export actual types
    form?: Element & { validate (): boolean }
  }

  public async submit (...params: ActionParams): Promise<void> {
    if (this.$refs.form && !this.$refs.form.validate()) return

    this.errorMessage = ''
    this.loading = true

    try {
      await Promise.resolve(this.action.apply(this, params))
      // tslint:disable-next-line:no-any
    } catch (error: any) {
      const message = this.getErrorMessage({
        error,
        response: (error && error.response) || null,
        status: (error && error.response && error.response.status) || null,
        data: (error && error.response && error.response.data) || null
      })

      let messageKey
      let messageParams
      if (typeof message === 'object') {
        messageParams = message.params
        messageKey = message.key
      } else {
        messageKey = message
      }
      if (messageKey) this.errorMessage = messageKey
      if (messageParams) this.errorParams = messageParams

      if (!messageKey) {
        this.errorMessage = 'error.unknown'
        captureException(error, 'Unknown error in submitted action')
      }
    } finally {
      this.loading = false
    }
  }

  get rules (): VInputRules {
    return {
      email: [v => !!v || this.$t('user.new.rules.email'),
        v => { return this.patternMail.test(v) || this.$t('user.new.rules.emailInvalid') }
      ],
      firstname: [v => !!v || this.$t('user.new.rules.firstName'),
        v => v.length > 1 || this.$t('user.new.rules.firstNameLength'),
        v => this.patternName.test(v) || this.$t('user.new.rules.firstNameIllegal')
      ],
      lastname: [v => !!v || this.$t('user.new.rules.lastName'),
        v => v.length > 1 || this.$t('user.new.rules.lastNameLength'),
        v => this.patternName.test(v) || this.$t('user.new.rules.lastNameIllegal')
      ]
    }
  }

  protected async action (..._params: ActionParams): Promise<void> { /* To be implemented by mixin caller */ }
  protected getErrorMessage (_e: IActionError): IActionErrorMessage | void {
    // sera réimplémentée au besoin
    if (_e.status === 409) return 'user.new.rules.emailExists'
    if (_e.status === 400) return _e.response?.data.message
    if (_e.status === 401) return 'error.notAllowed'
    return undefined
  }
  protected onError (): void { /* May be implemented by mixin caller */ }
}
