
















































import Component, { mixins } from 'vue-class-component'
import store from '~store'
import ActionFormMixin, { IActionError } from '~lib/action_form'

@Component
export default class Login extends mixins(ActionFormMixin) {
  public email = ''
  public password = ''

  public async action () {
    await store.login(this.email, this.password)

    if (store.currentUser) {
      // logged user exists : user is admin or escrow
      if (store.currentUser.passwordShouldBeUpdated) {
        await this.$router.push({ name: 'password_update' })
        return
      }

      await this.$router.push({ name: 'organizations list' })
    } else {
      // logged user does not exist : user must provide a validation code
      await this.$router.push({ name: 'login_confirm', params: { email: this.email, password: this.password } })
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 404) return 'login.error.credentials'
    if (e.status === 400) return 'login.error.timeoutCredentials'
    if (e.status === 423) return e.response?.data.message
    return undefined
  }
}
