














































import Component, { mixins } from 'vue-class-component'
import api from '~api'
import ActionFormMixin from '~lib/action_form'
import store from '~store'

@Component
export default class UpdateKeys extends mixins(ActionFormMixin) {
  public sshKey = ''
  public successOpen = false

  mounted () {
    this.sshKey = store.currentUser!.sshKey
  }

  public async action () {
    await api.user.update(store.currentUser!.id, {
      sshKey: this.sshKey
    })
    this.$set(store.currentUser!, 'sshKey', this.sshKey)
    this.successOpen = true
  }
}
