
























































































































import Component, { mixins } from 'vue-class-component'
import api, { ISelfInfo } from '~api'

import store from '~store'
import ActionFormMixin, {IActionError} from '~lib/action_form'

import DepositDownload from '~pages/deposits/download.vue'
import Signature from '~components/signature.vue'
import { Wallet } from 'ethers'
import uuidv4 from '~lib/uuid'
import timeout from '~lib/timeout'

@Component({ components: { DepositDownload, Signature } })
export default class ViewUser extends mixins(ActionFormMixin) {
  public admin: ISelfInfo | null = null
  public showInsertDialog = false
  public showDeleteDialog = false

  async mounted () {
    await this.submit()
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  get isCurrentAdmin () {
    return this.$route.params.id === store.currentUser!.id
  }

  get name () {
    return this.admin!.civility + ' ' + this.admin!.firstName + ' ' + this.admin!.lastName.toUpperCase()
  }

  async action () {
    this.admin = (await api.admin.get(this.$route.params.id)).data
  }

  public async resetAdmin () {
    await api.admin.reset(this.admin!.id)
    await this.goToAdminList()
  }

  public async goToAdminList () {
    await this.$router.push({ name: 'admins list' })
  }

  async showInsertAdmin () {
    this.showInsertDialog = true
  }

  async showDeleteAdmin () {
    if (this.admin!.ethereumAddress !== null) {
      this.showDeleteDialog = true
    } else {
      await api.admin.delete(this.admin!.id, null, null)
      return this.goToAdminList()
    }
  }

  async onInsertWallet (wallet: Wallet) {
    const uuid = uuidv4()
    await store.insertAdmin(wallet, this.admin!.id, this.admin!.ethereumAddress!, uuid)
    window.location.reload()
  }

  async onDeleteWallet (wallet: Wallet) {
    const uuid = uuidv4()
    await store.deleteAdmin(wallet, this.admin!.id, this.admin!.ethereumAddress!, uuid)
    this.showDeleteDialog = false
    await this.goToAdminList()
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'admins list' })
    }
    return undefined
  }
}
