



























































































import Component, { mixins } from 'vue-class-component'
import axios from 'axios'
import api from '~api'

import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'
import timeout from '~lib/timeout'

@Component
export default class Deposits extends mixins(ActionFormMixin) {
  public selectedFiles: File | File[] | null = null
  public validationText = ''
  public version = ''
  public patterDescription: RegExp = /[<>/;{}]/
  public patternFile: RegExp = /\.(zip|tar(\.(gz|bz|xz))?|tgz)$/
  public latestEvent: ProgressEvent<XMLHttpRequestEventTarget> | null = null
  public cancelTokenSource = axios.CancelToken.source();

  get status () {
    if (!this.latestEvent || !this.latestEvent.lengthComputable || this.latestEvent.loaded === 0) return 'preparing'
    if (this.latestEvent.loaded === this.latestEvent.total) return 'finishing'
    return 'uploading'
  }

  get isIndefinite () {
    return !this.latestEvent || !this.latestEvent.lengthComputable || this.latestEvent.loaded === 0 || this.latestEvent.loaded === this.latestEvent.total
  }

  get progress () {
    return this.latestEvent && (this.latestEvent.loaded / this.latestEvent.total) * 100
  }

  get depositsRules (): VInputRules {
    return {
      description: [
        (v) => !!v || this.$t('deposits.new.rules.description'),
        (v) => !this.patterDescription.test(v) || this.$t('deposits.new.rules.illegal'),
        (v) => v.length >= 10 || this.$t('deposits.new.rules.short')
      ],
      file: [
        (v) => !!v || this.$t('deposits.new.rules.file'),
        (v) => this.patternFile.test(v.name) || this.$t('deposits.new.rules.extension')
      ]
    }
  }

  async action () {
    if (!this.selectedFiles) return

    const file = Array.isArray(this.selectedFiles) ? this.selectedFiles[0] : this.selectedFiles
    const repositoryId = this.$route.params.repositoryId

    this.latestEvent = null

    const deposit = (await api.deposits.create(repositoryId, { description: this.validationText, version: this.version }, file, (e) => {
      this.latestEvent = e

      this.$router.push({ name: 'deposits view', params: { id: deposit.id } })
    }, this.cancelTokenSource.token)).data

    await this.$router.push({ name: 'deposits view', params: { id: deposit.id } })

    this.loading = false
    this.cancelTokenSource = axios.CancelToken.source()
  }

  cancelRequest () {
    this.cancelTokenSource.cancel('File upload cancelled by user')
  }

  protected getErrorMessage (e: IActionError): IActionErrorMessage | void {
    if (e.status === 504) this.$router.push({ name: 'deposits list' })
    // l'erreur 400 peut provenir de la pièce jointe qui n'a pas la bonne extension ou un problème d'espace disque système
    if (e.status === 400) return 'deposits.new.error.serverError'
    if (e.status === 500) return e.response?.data.message
    if (e.status === 413) return 'deposits.new.error.requestEntityTooLarge'
    if (e.status === 406) return e.response?.data.message
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
