

























import Component, { mixins } from 'vue-class-component'
import api, { IRepository } from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import store from '~store'
import timeout from '~lib/timeout'

@Component
export default class Repositories extends mixins(ActionFormMixin) {
    public repositories: IRepository[] | null = []

    mounted () {
      this.submit()
    }

    get isDepositary () {
      return store.currentUser!.role === 'DEPOSITARY'
    }

    get isMainDepositary () {
      return store.mainDepositary.access === 'MAIN'
    }

    get isBeneficiary () {
      return store.currentUser && store.currentUser.role === 'BENEFICIARY'
    }

    get filterList () {
      return this.repositories?.filter(x => this.isMainDepositary || this.isBeneficiary ||
          store.mainDepositary.repositoriesList.includes(x.id)) ?? []
    }

    async action () {
      this.repositories = (await api.repositories.list()).data
    }

    public getErrorMessage (e: IActionError) {
      if (e.status === 403) this.$router.push({ name: 'forbidden' })
      if (e.status === 404) this.$router.push({ name: '404' })
      if (e.status === 401) {
        timeout()
        return 'error.timeout'
      }
      return undefined
    }
}
