

































import Component, { mixins } from 'vue-class-component'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import { DataTableHeader } from 'vuetify/types'
import api, { IAccessEvent } from '~api'
import store from '~store'
import timeout from '~lib/timeout'

@Component
export default class Events extends mixins(ActionFormMixin) {
  public rawEvents: IAccessEvent[] | null = []
  public projectNamesMap: {} | undefined
  public usernamesMap: {} | undefined

  mounted () {
    this.submit()
  }

  async action () {
    this.rawEvents = (await api.event.list()).data
    await store.getCorrespondanceTable()
    this.usernamesMap = store.usernameCorrespondanceTable
    await store.getProjectNames()
    this.projectNamesMap = store.projectNamesCorrespondanceTable
  }

  get headers (): DataTableHeader[] {
    return [
      {
        text: this.$t('event.table.beneficiary') as string,
        value: 'beneficiary',
        sortable: false
      },
      {
        text: this.$t('event.table.project') as string,
        value: 'project'
      },
      {
        text: this.$t('event.table.startDate') as string,
        value: 'windowStartsAt'
      },
      {
        text: this.$t('event.table.endDate') as string,
        value: 'windowEndsAt'
      }
    ]
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 500) return null
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
