















































































































































































import Component, { mixins } from 'vue-class-component'
import api, { IRepository } from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import timeout from '~lib/timeout'

@Component
export default class AddUser extends mixins(ActionFormMixin) {
  public firstName = ''
  public lastName = ''
  public role = ''
  public email = ''
  public sshkey = ''
  public civility = ''
  public typesOfRights = ''
  public rightsOnRepo : string[] = []
  public repositories: IRepository[] | null = []
  public loadingData = false
  public isEditing = false
  public allChecked: string = 'false'

  async mounted () {
    this.repositories = (await api.repositories.list()).data
    if (!this.$route.params.id) {
      return
    }

    this.loadingData = true

    try {
      const user = await api.user.get(this.$route.params.id)
      this.firstName = user.data.firstName
      this.lastName = user.data.lastName
      this.civility = user.data.civility
      this.role = user.data.role
      this.email = user.data.email
      this.sshkey = user.data.sshKey

      const depositaryPermissions = (await api.user.getDepositaryPermissions(this.$route.params.id)).data
      this.typesOfRights = depositaryPermissions.access
      const repoList = depositaryPermissions.repositoriesList as unknown as string
      for (const repo of repoList.split(', ')) {
        this.rightsOnRepo.push(repo)
      }

      if (this.rightsOnRepo.length === this.repositories.length) {
        this.allChecked = 'ALL'
      }

      this.isEditing = true
    } finally {
      this.loadingData = false
    }
  }

  get roleChoices () {
    return [{ value: 'DEPOSITARY', name: this.$t('user.role.depositary') }, { value: 'BENEFICIARY', name: this.$t('user.role.beneficiary') }]
  }

  get typesOfRightsChoices () {
    return [{ value: 'FULL', name: this.$t('user.type.full') }, { value: 'RESTRICTED', name: this.$t('user.type.restricted') }]
  }

  public onAddAll (value: string) {
    if (value === 'ALL' && this.repositories) {
      this.rightsOnRepo = []
      for (const repo of this.repositories) {
        this.rightsOnRepo.push(repo.id)
      }
    } else {
      this.rightsOnRepo = []
    }
  }

  public onChange () {
    if (this.repositories && this.rightsOnRepo.length === this.repositories.length) {
      this.allChecked = 'ALL'
    } else {
      this.allChecked = 'false'
    }
  }

  async action () {
    const user = {
      firstName: this.firstName,
      lastName: this.lastName,
      userRole: this.role,
      email: this.email,
      sshKey: this.sshkey,
      civility: this.civility,
      typesOfRights: this.typesOfRights,
      rightsOnRepo: this.rightsOnRepo
    }

    if (this.typesOfRights === 'FULL' && this.repositories) {
      const listRepo = []
      for (const repo of this.repositories) {
        listRepo.push(repo.id)
      }
      user.rightsOnRepo = listRepo
    }

    let newUser

    if (this.$route.params.id) {
      newUser = (await api.user.update(this.$route.params.id, user)).data
    } else {
      newUser = (await api.user.create(user)).data
    }

    await this.$router.push({ name: 'users view', params: { id: newUser.id } })
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 409) return 'user.new.error.conflict'
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
