






















































import Component, { mixins } from 'vue-class-component'
import api from '~api'
import timeout from '~lib/timeout'

import ActionFormMixin, { IActionError } from '~lib/action_form'
import uuidv4 from '~lib/uuid'
import store from '~store'
import { Wallet } from 'ethers'
import DepositsTable from '~pages/deposits/deposits_table.vue'
import Signature from '~components/signature.vue'

@Component({
  components: { DepositsTable, Signature }
  })
export default class Deposits extends mixins(ActionFormMixin) {
  public showAccessRequestDialog = false
  public cloneUri: string | null = null
  public repositoryName: string | null = null
  public gitForbidden = true

  data () {
    return {
      select: { text: this.$t('deposits.depositTable.etat.done'), value: 'done' },
      statusList: [
        { text: this.$t('deposits.depositTable.etat.all'), value: 'null' },
        { text: this.$t('deposits.depositTable.etat.done'), value: 'done' },
        { text: this.$t('deposits.depositTable.etat.loading'), value: 'loading' },
        { text: this.$t('deposits.depositTable.etat.error'), value: 'error' }
      ]
    }
  }

  get isBeneficiaryAndValidated () {
    return store.currentUser!.role === 'BENEFICIARY' && !store.currentUser!.userShouldBeInserted
  }

  get isDepositary () {
    return store.currentUser!.role === 'DEPOSITARY'
  }

  get repositoryId () {
    return this.$route.params.repositoryId
  }

  get cloneCommand () {
    if (this.gitForbidden) return 'deposit.error.cloneCommandAccess'
    if (!this.cloneUri) return 'deposit.error.cloneCommandAccess'
    if (store.currentUser!.role === 'BENEFICIARY' && (this.cloneUri === 'DENIED')) return 'deposit.error.cloneCommandAccess'
    return `git clone ${this.cloneUri}`
  }

  public mounted () {
    this.submit()
  }

  async isGitUriForbidden () {
    const rep = await api.repositories.checkGitCloneUri(this.repositoryId)
    if (rep.status === 200) {
      this.gitForbidden = (rep.data.auth !== 'OK')
      return
    }
    throw new Error(rep.status.toString())
  }

  public async action () {
    this.repositoryName = (await api.repositories.get(this.$route.params.repositoryId)).data.description
    await this.isGitUriForbidden()
    this.cloneUri = (await api.repositories.gitCloneUri(this.repositoryId)).data
  }

  async onWallet (wallet: Wallet) {
    const uuid = uuidv4()
    const address = store.currentUser!.organization!.contractAddress
    try {
      await store.registerAccessRequest(wallet, uuid, address)
    } catch (e) {
      if (e.message === 'invalid password') {
        this.errorMessage = 'update_password.error_message.invalid_current_password'
        return
      } else if (e.code === 403) {
        this.errorMessage = 'deposits.makeRequest.error_message.invalid_role'
      }
      throw e
    }
    this.showAccessRequestDialog = false
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
