





















































































































































































import Component, { mixins } from 'vue-class-component'
import store from '~store'
import ActionFormMixin from '~lib/action_form'
import exaegisLogo from '~styles/logo.png'
import api from '~api'
import { captureException } from '~lib/sentry'

@Component
export default class SideBar extends mixins(ActionFormMixin) {
  public exaegisLogo = exaegisLogo
  public mini = false
  public notifRequest: number = 0
  public notifLocked: number = 0
  width: string = '328px'

  get username () {
    return store.currentUser!.firstName + ' ' + store.currentUser!.lastName
  }

  get smallUsername () {
    return store.currentUser!.firstName.toUpperCase().charAt(0) + '-' + store.currentUser!.lastName.toUpperCase().charAt(0)
  }

  get isAdmin () {
    return store.currentUser && (store.currentUser.role === 'ADMIN' || store.currentUser.role === 'ESCROWAGENT')
  }

  get isEscrowAgent () {
    return store.currentUser && store.currentUser.role === 'ESCROWAGENT'
  }

  get isBeneficiary () {
    return store.currentUser && store.currentUser.role === 'BENEFICIARY'
  }

  get isDepositary () {
    return store.currentUser && store.currentUser.role === 'DEPOSITARY'
  }

  get isMainDepositary () {
    return store.currentUser && store.currentUser.role === 'DEPOSITARY' ? store.mainDepositary.access === 'MAIN' : false
  }

  get isAccepted () {
    return store.currentUser && !store.currentUser.userShouldBeInserted
  }

  get tenantName () {
    return store.currentUser!.organization?.name
  }

  get role () {
    return 'user.role.' + (store.currentUser!.role.toLowerCase())
  }

  async mounted () {
    await store.fmainDepositary()
  }

  async isRequest () {
    try {
      const response = (await api.requests.list())

      if (Array.isArray(response.data)) {
        this.notifRequest = response.data.length

        return response.data.length > 0
      } else {
        return false
      }
    } catch (error) {
      captureException(error, 'Unknown error when calling request endpoint')
      return false
    }
  }

  async isLocked () {
    try {
      const response = (await api.locked.list())

      if (Array.isArray(response.data)) {
        this.notifLocked = response.data.length

        return response.data.length > 0
      } else {
        return false
      }
    } catch (error) {
      captureException(error, 'Unknown error when calling locked endpoint')
      return false
    }
  }

  public async action () {
    await store.logout()
    await this.$router.push({ name: 'login' })
  }

  public changeSize () {
    this.mini = !this.mini

    if (this.mini) {
      this.width = '56px'
    } else {
      this.width = '328px'
    }
  }
}
