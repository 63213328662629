import '~lib/sentry'

import Vue from 'vue'

import i18n from '~locale'
import router from '~routes'
import store from '~store'
import { vuetify } from '~styles'

import Main from '~main.vue'

// tslint:disable-next-line:no-unused-expression
new Vue({ // wotan-disable-line no-unused-expression
  el: document.getElementById('content') as Element,
  render: (h) => h(Main),
  router,
  store: store.$store,
  i18n,
  // tslint:disable-next-line:ban-ts-ignore
  // @ts-ignore
  vuetify
})
