























































































import Component, { mixins } from 'vue-class-component'
import api, { IRequest, ISelfInfo } from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import Signature from '~components/signature.vue'
import store from '~store'
import { Wallet } from 'ethers'
import timeout from '~lib/timeout'

@Component({ components: { Signature } })
export default class ViewRequest extends mixins(ActionFormMixin) {
    public request: IRequest | null = null
    public showResponseDialog = false
    public accepted: boolean | null = null

    public rejectingLoading = false

    async mounted () {
      await this.submit()
    }

    async action () {
      this.request = (await api.requests.get(this.$route.params.id)).data
    }

    public showWalletDialog (choice: boolean) {
      this.accepted = choice
      this.showResponseDialog = true
    }

    public name (requester: ISelfInfo) {
      return requester.firstName + ' ' + requester.lastName
    }

    public type (requestType: string) {
      return this.$t('request.type.' + requestType.toLowerCase())
    }

    async onResponseWallet (wallet: Wallet) {
      await store.acceptRequest(this.$route.params.id, this.request!, wallet)
      this.showResponseDialog = false
      await this.$router.push({ name: 'requests list' })
    }

    async rejectRequest () {
      this.rejectingLoading = true
      try {
        await store.rejectRequest(this.$route.params.id)
        await this.$router.push({ name: 'requests list' })
      } finally {
        this.rejectingLoading = false
      }
    }

    public getErrorMessage (e: IActionError) {
      if (e.status === 403) this.$router.push({ name: 'forbidden' })
      if (e.status === 404) this.$router.push({ name: '404' })
      if (e.status === 401) {
        timeout()
        this.$router.push({ name: 'requests list' })
      }
      return undefined
    }
}
