






























































































































import Component, { mixins } from 'vue-class-component'
import api, { IOrganization, IRepository, ISelfInfo, ISignatory, IUserInfo } from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import { DataTableHeader } from 'vuetify/types'
import UserDialog from '~components/user_dialog.vue'
import DepositDialog from '~components/deposit_dialog.vue'
import RepositoryDialog from '~components/repository_dialog.vue'
import timeout from '~lib/timeout'

@Component({ components: { RepositoryDialog, DepositDialog, UserDialog } })
export default class ViewOrganization extends mixins(ActionFormMixin) {
  public organization: IOrganization | null = null
  public selectedUser: ISelfInfo | null = null
  public signatory: ISignatory | null = null
  public selectedRepository: IRepository | null = null
  public showUserDialog: boolean = false
  public showRepositoryDialog: boolean = false
  public users: IUserInfo[] | null = []


  mounted () {
    this.submit()
  }

  get repositoryHeaders (): DataTableHeader[] {
    return [
      {
        text: this.$t('deposits.description') as string,
        value: 'description',
        sortable: false
      },
      {
        text: this.$t('deposits.createdAt') as string,
        value: 'createdAt'
      }
    ]
  }

  get userHeaders (): DataTableHeader[] {
    return [
      {
        text: this.$t('deposits.description') as string,
        value: 'description',
        sortable: false
      },
      {
        text: this.$t('deposits.role') as string,
        value: 'role'
      }
    ]
  }

  get signatoryHeaders (): DataTableHeader[] {
    return [
      {
        text: this.$t('admin.new.field.civility') as string,
        value: 'civility',
        sortable: false
      },
      {
        text: this.$t('deposits.description') as string,
        value: 'description',
        sortable: false
      },
      {
        text: this.$t('user.view.mail') as string,
        value: 'email',
        sortable: false
      }
    ]
  }

  get footerProjectsProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [10]
    }
  }

  get footerUsersProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [6]
    }
  }

  async action () {
    this.organization = (await api.organizations.get(this.$route.params.id)).data
    this.users = (await api.organizations.getUsers(this.organization.id)).data
  }

  public async showUser (user: ISelfInfo) {
    this.selectedUser = (await api.organizations.getUser(this.organization!.id, user.id)).data
    this.showUserDialog = true
  }

  public async showDeposit (repository: IRepository) {
    this.selectedRepository = (await api.organizations.getRepository(this.organization!.id, repository.id)).data
    this.showRepositoryDialog = true
  }

  public role (userRole: string) {
    return this.$t('user.role.' + userRole.toLowerCase())
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'organizations list' })
    }
    return undefined
  }
}

