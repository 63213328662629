




































import Component, { mixins } from 'vue-class-component'

import ActionFormMixin from '~lib/action_form'
import { Prop } from 'vue-property-decorator'
import api, { IDeposit, IRepository } from '~api'
import DepositDialog from '~components/deposit_dialog.vue'
import { DataTableHeader } from 'vuetify/types'

@Component({ components: { DepositDialog } })
export default class RepositoryDialog extends mixins(ActionFormMixin) {
    @Prop({ type: Boolean, default: false })
    public viewDialog!: boolean

    @Prop({ type: Object, default: null })
    public repository!: IRepository

    public selectedDeposit: IDeposit | null = null
    public showDepositDialog: boolean = false

    mounted () {
      this.submit()
    }

    get depositsHeader (): DataTableHeader[] {
      return [
        {
          text: this.$t('deposits.description') as string,
          value: 'description',
          sortable: false
        },
        {
          text: this.$t('deposits.createdAt') as string,
          value: 'createdAt'
        }
      ]
    }

    get footerProps () {
      return {
        disableItemsPerPage: true,
        itemsPerPageOptions: [10]
      }
    }

    public async showDeposit (deposit: IRepository) {
      this.selectedDeposit = (await api.organizations.getDeposit(this.repository.organization.id, this.repository.id, deposit.id)).data
      this.showDepositDialog = true
    }

    public closeDialog () {
      this.$emit('viewDialog', false)
      this.$emit('selectedDeposit', null)
    }
}
