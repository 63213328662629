

































import Component, { mixins } from 'vue-class-component'
import api, { IRequest } from '~api'
import ActionFormMixin, {IActionError} from '~lib/action_form'
import { DataTableHeader } from 'vuetify/types'
import timeout from '~lib/timeout'

@Component
export default class Requests extends mixins(ActionFormMixin) {
  public requests: IRequest[] | null = []

  mounted () {
    this.submit()
  }

  public type (requestType: string) {
    return this.$t('request.type.' + requestType.toLowerCase())
  }

  async action () {
    this.requests = (await api.requests.list()).data
  }

  get headers (): DataTableHeader[] {
    return [
      {
        text: this.$t('request.list.type') as string,
        value: 'type'
      },
      {
        text: this.$t('request.list.createdAt') as string,
        value: 'createdAt'
      },
      {
        text: this.$t('request.list.target') as string,
        value: 'target.id',
        sortable: false
      }
    ]
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}
