



























import Component, { mixins } from 'vue-class-component'
import api from '~api'

import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'
import timeout from '~lib/timeout'

@Component
export default class NewRepository extends mixins(ActionFormMixin) {
  public description = ''
  public notContainSpecialCharacters : RegExp = /[^A-Za-z0-9-_\s]/

  public loadingData = false

  get editing () {
    return !!this.$route.params.repositoryId
  }

  async mounted () {
    if (!this.editing) {
      return
    }

    this.loadingData = true

    try {
      const repository = await api.repositories.get(this.$route.params.repositoryId)
      this.description = repository.data.description
    } finally {
      this.loadingData = false
    }
  }

  get repositsRules (): VInputRules {
    return {
      description: [
        (v) => !!v || this.$t('repositories.new.rules.description'),
        (v) => !this.notContainSpecialCharacters.test(v) || this.$t('repositories.new.rules.illegalCharacters')
      ]
    }
  }

  async action () {
    let repository

    if (this.editing) {
      repository = (await api.repositories.update(this.$route.params.repositoryId, { description: this.description })).data
    } else {
      repository = (await api.repositories.create({ description: this.description })).data
    }

    await this.$router.push({ name: 'deposits list', params: { repositoryId: repository.id } })
  }

  public getErrorMessage (e: IActionError): IActionErrorMessage | void {
    if (e.status === 500) return 'repositories.new.errors.conflict'
    if (e.status === 400) return e.response?.data.message
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
  }
}
