


























































































import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import api, { ISelfInfo } from '~api'
import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'

@Component
export default class UserDialog extends mixins(ActionFormMixin) {
  @Prop({ type: Boolean, default: false })
  public viewDialog!: boolean

  @Prop({ type: Object, default: null })
  public user!: ISelfInfo | null

  public resetSuccess = false

  get name () {
    return this.user!.firstName + ' ' + this.user!.lastName.toUpperCase()
  }

  public role (userRole: string) {
    return this.$t('user.role.' + userRole.toLowerCase())
  }

  public showDate (epochString: string) {
    return epochString ? new Date(parseInt(epochString) * 1000).toLocaleDateString('fr-FR') : ''
  }

  public async action () {
    await api.user.reset(this.user!.id)
    this.resetSuccess = true
  }

  protected getErrorMessage (e: IActionError): IActionErrorMessage | void {
    if (e.status === 409) return 'user.view.errors.deadlock'
  }

  public closeDialog () {
    this.resetSuccess = false
    this.errorMessage = ''
    this.$emit('viewDialog', false)
  }
}
